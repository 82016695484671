import React from 'react';
import { motion } from 'framer-motion';

const SimpleComingSoonPage = () => {
  return (
    <div className="min-h-screen bg-background flex flex-col justify-center items-center px-4 text-text">
      <motion.h1
        className="text-4xl sm:text-5xl font-light mb-4 text-center font-the-seasons"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        Coming Soon
      </motion.h1>
      <motion.div
        className="w-16 h-0.5 bg-text mb-4"
        initial={{ width: 0 }}
        animate={{ width: 64 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      />
      <motion.p
        className="text-lg sm:text-xl mb-8 text-center max-w-md"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.6 }}
      >
        We're putting the finishing touches on this page. Check back soon for something special.
      </motion.p>
    </div>
  );
};

export default SimpleComingSoonPage;