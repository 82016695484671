import React from 'react';
import { motion } from 'framer-motion';
import ImanSaharImage from '../images/imanSahar.JPG';
import CookingImage from '../images/imanSahar1.JPG';

const AboutPage = () => {
  return (
    <div className="bg-background min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <motion.h1 
          className="text-4xl sm:text-5xl md:text-6xl font-light text-center text-text mb-12 font-the-seasons"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          About Iman Sahar
        </motion.h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="relative h-0 pb-[125%] md:pb-[150%] overflow-hidden rounded-lg shadow-lg"
          >
            <img 
              src={ImanSaharImage} 
              alt="Iman Sahar" 
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </motion.div>
          <motion.div
            className="flex flex-col justify-center space-y-6"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <p className="text-text text-lg leading-relaxed">
              Welcome to a culinary journey with Iman Sahar, a passionate cook with Afghan roots. Raised in America, Iman found inspiration in her culture and developed a deep interest in Afghan cuisine. At 22, she continues to learn and explore, mastering recipes and techniques through dedication and curiosity.
            </p>
            <p className="text-text text-lg leading-relaxed">
              Iman's blog is a celebration of authentic Afghan dishes and a reflection of her love for cooking. Her passion extends beyond her heritage, embracing culinary traditions from around the world. She enjoys discovering new ingredients and techniques, blending them with Afghan flavors to create unique and delightful dishes.
            </p>
          </motion.div>
        </div>
        
        <motion.div
          className="mb-16 px-4 py-8 bg-opacity-50 rounded-lg shadow-inner"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <p className="text-text text-lg leading-relaxed mb-6">
            While Iman loves sharing the rich culinary traditions of her heritage and learning about other cultures, she also balances this with a focus on simple, healthy meals. As a health-conscious person, she believes in maintaining a good balance in her diet and lifestyle.
          </p>
          <p className="text-text text-lg leading-relaxed">
            This blog offers a collection of recipes that highlight Iman's diverse culinary journey. Whether it's a comforting Afghan stew, a vibrant Middle Eastern salad, a fusion dish combining various influences, or a quick and healthy meal, each recipe is crafted with care and respect for its origins.
          </p>
        </motion.div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          <motion.div
            className="flex flex-col justify-center space-y-6 order-2 md:order-1"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.8 }}
          >
            <p className="text-text text-lg leading-relaxed">
              Iman believes that food is a universal language that brings people together. Her mission is to make these diverse recipes accessible to everyone, from seasoned cooks to beginners. With clear instructions and helpful tips, she aims to make cooking an enjoyable experience.
            </p>
            <p className="text-text text-lg leading-relaxed">
              Join Iman on this flavorful adventure, where the rich flavors of Afghanistan meet the culinary wonders of the world. Her blog is a place to explore, learn, and savor, celebrating the beauty of food and the stories it tells. All the while, Iman remains connected to her Afghan heritage, honoring the roots that shape her culinary identity.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="relative h-0 pb-[125%] md:pb-[150%] overflow-hidden rounded-lg shadow-lg order-1 md:order-2"
          >
            <img 
              src={CookingImage} 
              alt="Iman Sahar cooking" 
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </motion.div>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.2 }}
          className="text-center px-4 py-8 bg-opacity-50 rounded-lg shadow-inner"
        >
          <p className="text-text text-lg leading-relaxed italic">
            Whether you are looking to explore Afghan cuisine, are curious about other cultural flavors, or seek healthy meal options, Iman's blog offers a welcoming space to discover and enjoy. Let's embark on this journey together, appreciating the elegance and simplicity of good food, and the balance that makes life nourishing and delightful.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutPage;