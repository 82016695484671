import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-9FER9ZB41J');
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};