import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Search } from 'lucide-react';
import '../fonts.css';

const Header = ({ toggleSidebar, isSidebarOpen }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible((prevScrollPos > currentScrollPos && currentScrollPos > 0) || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
      setSearchTerm(''); // Clear the search input after searching
    }
  };

  return (
    <header className={`fixed top-0 left-0 right-0 bg-component-bg border-b border-border transition-all duration-300 z-50 ${visible ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Mobile view */}
          <div className="flex items-center justify-between w-full md:hidden">
            <button
              type="button"
              className="bg-component-bg rounded-md p-2 inline-flex items-center justify-center text-text hover:text-text-light hover:bg-button-hover focus:outline-none focus:ring-2 focus:ring-inset focus:ring-border transition duration-300 ease-in-out"
              onClick={toggleSidebar}
            >
              <span className="sr-only">Open sidebar</span>
              <Menu className="h-6 w-6" />
            </button>
            <Link 
              to="/" 
              className="text-2xl font-light text-text transition-all duration-300" 
              style={{ fontFamily: '"The Seasons", serif' }}
            >
              iman sahar
            </Link>
            <form onSubmit={handleSearch} className="relative">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full bg-white border border-border rounded-full py-1 px-3 text-sm font-light focus:outline-none focus:ring-2 focus:ring-border focus:border-border transition duration-300 ease-in-out"
              />
              <button type="submit" className="absolute right-2 top-1/2 transform -translate-y-1/2">
                <Search className="h-4 w-4 text-text" />
              </button>
            </form>
          </div>

          {/* Desktop view */}
          <div className="hidden md:flex items-center justify-between w-full">
            <Link 
              to="/" 
              className={`text-4xl font-light text-text transition-all duration-300 ${
                isSidebarOpen ? 'ml-64' : 'ml-16'
              }`}
              style={{ fontFamily: '"The Seasons", serif' }}
            >
              iman sahar
            </Link>
            <div className="flex items-center space-x-10">
              <Link 
                to="/recipes" 
                className="text-base font-light text-text hover:text-text-light transition duration-300 ease-in-out"
              >
                RECIPES
              </Link>
              <Link 
                to="/coming-soon" 
                className="text-base font-light text-text hover:text-text-light transition duration-300 ease-in-out"
              >
                BLOG
              </Link>
              <Link 
                to="/about" 
                className="text-base font-light text-text hover:text-text-light transition duration-300 ease-in-out"
              >
                ABOUT
              </Link>
              <form onSubmit={handleSearch} className="relative">
                <input
                  type="text"
                  placeholder="Search site..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full bg-white border border-border rounded-full py-2 pl-4 pr-10 text-sm font-light focus:outline-none focus:ring-2 focus:ring-border focus:border-border transition duration-300 ease-in-out"
                />
                <button type="submit" className="absolute right-3 top-1/2 transform -translate-y-1/2">
                  <Search className="h-4 w-4 text-text" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;