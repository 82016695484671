import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeft, Clock, Users } from 'lucide-react';

const SingleRecipePage = () => {
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/${id}/`);
        setRecipe(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch recipe. Please try again later.');
        setLoading(false);
      }
    };

    fetchRecipe();

    // Load Pinterest SDK
    const script = document.createElement('script');
    script.src = '//assets.pinterest.com/js/pinit.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [id]);

  const goToAllRecipes = () => {
    navigate('/recipes');
  };

  const pinRecipe = () => {
    const url = window.location.href;
    const media = recipe.images[0]?.image || "/api/placeholder/800/800";
    const description = `Check out this ${recipe.title} recipe!`;
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isIOS) {
      const pinterestAppUrl = `pinterest://pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(media)}&description=${encodeURIComponent(description)}`;
      const webFallback = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(media)}&description=${encodeURIComponent(description)}`;
      
      window.location.href = pinterestAppUrl;

      setTimeout(() => {
        window.location.href = webFallback;
      }, 500);
    } else if (isAndroid) {
      const androidIntentUrl = `intent://pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(media)}&description=${encodeURIComponent(description)}#Intent;package=com.pinterest;scheme=https;end`;
      const webFallback = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(media)}&description=${encodeURIComponent(description)}`;

      window.location.href = androidIntentUrl;

      setTimeout(() => {
        window.location.href = webFallback;
      }, 500);
    } else {
      window.open(
        `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(media)}&description=${encodeURIComponent(description)}`,
        'pinterest-share-dialog',
        'width=626,height=436'
      );
    }
  };

  if (loading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
  if (!recipe) return <div className="text-center py-10">Recipe not found.</div>;

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <button onClick={goToAllRecipes} className="inline-flex items-center text-text hover:text-text-light mb-8 transition duration-300 ease-in-out">
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back to Recipes
      </button>
      
      <div className="flex flex-col md:flex-row mb-12">
        <div className="w-full md:w-1/2 md:pr-8 mb-6 md:mb-0">
          <div className="w-full pb-[100%] relative">
            <img 
              src={recipe.images[0]?.image || "/api/placeholder/800/800"} 
              alt={recipe.title} 
              className="absolute top-0 left-0 w-full h-full object-cover"
            />
          </div>
          <button 
            onClick={pinRecipe}
            className="mt-4 w-full md:w-auto bg-black hover:bg-gray-800 text-white font-bold py-3 px-6 rounded transition duration-300 ease-in-out"
          >
            Pin this recipe
          </button>
        </div>
        <div className="w-full md:w-1/2 flex flex-col justify-center">
          <h1 className="text-5xl font-light text-text mb-6 font-the-seasons">{recipe.title}</h1>
          <p className="text-text leading-relaxed mb-6">{recipe.body}</p>
          <div className="flex flex-wrap items-center text-text text-sm">
            <div className="flex items-center mr-4 mb-2">
              <Clock className="h-4 w-4 mr-1" />
              <span>Prep: {recipe.prep_time} mins</span>
            </div>
            <div className="flex items-center mr-4 mb-2">
              <Clock className="h-4 w-4 mr-1" />
              <span>Cook: {recipe.cooking_time} mins</span>
            </div>
            <div className="flex items-center mb-2">
              <Users className="h-4 w-4 mr-1" />
              <span>{recipe.servings} servings</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl font-light text-text mb-4 font-the-seasons">Ingredients</h2>
          {recipe.ingredient_sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-6">
              <h3 className="text-xl font-semibold text-text mb-2">{section.name}</h3>
              <ul className="list-disc list-inside text-text">
                {section.ingredients.map((ingredient, index) => (
                  <li key={index} className="mb-2">{ingredient}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        
        <div>
          <h2 className="text-2xl font-light text-text mb-4 font-the-seasons">Instructions</h2>
          <ol className="list-decimal list-inside text-text">
            {recipe.instructions.map((instruction, index) => (
              <li key={index} className="mb-3">{instruction}</li>
            ))}
          </ol>
        </div>
      </div>
      
      {recipe.tips && recipe.tips.length > 0 && (
        <div className="mt-8">
          <h2 className="text-2xl font-light text-text mb-4 font-the-seasons">Tips</h2>
          <ul className="list-disc list-inside text-text">
            {recipe.tips.map((tip, index) => (
              <li key={index} className="mb-2">{tip}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SingleRecipePage;