import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { FaInstagram, FaTiktok, FaEnvelope } from 'react-icons/fa';
import emailjs from '@emailjs/browser';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    reply_to: '',
    message: '',
  });
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus(null);

    const templateParams = {
      ...formData,
      to_name: 'Iman Sahar',
    };

    emailjs.send('service_o7fwnlg', 'template_eczos6s', templateParams, 'hq0rguhE80GP0J_dn')
      .then((result) => {
        setStatus('success');
        setFormData({ from_name: '', reply_to: '', message: '' });
      }, (error) => {
        setStatus('error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="bg-gradient-to-b from-background to-background-light min-h-screen py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <motion.h1 
          className="text-4xl sm:text-5xl md:text-6xl font-light text-center text-text mb-16 font-the-seasons"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Get in Touch
        </motion.h1>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-20">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="bg-white bg-opacity-95 shadow-2xl rounded-lg p-8 sm:p-10"
          >
            <h2 className="text-3xl sm:text-4xl font-semibold mb-8 text-text">Contact Us</h2>
            <form ref={form} onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="from_name" className="block text-sm font-medium text-text mb-2">Name</label>
                <input
                  type="text"
                  id="from_name"
                  name="from_name"
                  value={formData.from_name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 rounded-md border border-gray-300 bg-gray-50 focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 transition duration-300"
                />
              </div>
              <div>
                <label htmlFor="reply_to" className="block text-sm font-medium text-text mb-2">Email</label>
                <input
                  type="email"
                  id="reply_to"
                  name="reply_to"
                  value={formData.reply_to}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 rounded-md border border-gray-300 bg-gray-50 focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 transition duration-300"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-text mb-2">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 rounded-md border border-gray-300 bg-gray-50 focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 transition duration-300"
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full bg-black hover:bg-gray-800 text-white font-bold py-3 px-6 rounded-lg transition-colors duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isLoading ? 'Sending...' : 'Send Message'}
              </button>
              {status === 'success' && (
                <p className="text-green-600 text-center font-semibold mt-4">Your message has been sent successfully!</p>
              )}
              {status === 'error' && (
                <p className="text-red-600 text-center font-semibold mt-4">There was an error sending your message. Please try again.</p>
              )}
            </form>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="space-y-12"
          >
            <div className="bg-white bg-opacity-95 shadow-2xl rounded-lg p-8 sm:p-10">
              <h2 className="text-3xl sm:text-4xl font-semibold mb-8 text-text">Follow Us</h2>
              <div className="flex space-x-8 justify-center sm:justify-start">
                <a href="mailto:contact@imansahar.com" className="text-text hover:text-gray-600 transition-colors duration-300">
                  <FaEnvelope size={36} />
                </a>
                <a href="https://www.instagram.com/imannsahar/" target="_blank" rel="noopener noreferrer" className="text-text hover:text-gray-600 transition-colors duration-300">
                  <FaInstagram size={36} />
                </a>
                <a href="https://www.tiktok.com/@imannsahar" target="_blank" rel="noopener noreferrer" className="text-text hover:text-gray-600 transition-colors duration-300">
                  <FaTiktok size={36} />
                </a>
              </div>
            </div>

            <div className="bg-white bg-opacity-95 shadow-2xl rounded-lg p-8 sm:p-10">
              <h2 className="text-3xl sm:text-4xl font-semibold mb-8 text-text">Visit Us</h2>
              <p className="text-lg sm:text-xl leading-relaxed text-text-light">
                We'd love to hear from you! Whether you have a question about a recipe, want to share your cooking experience, or just want to say hello, don't hesitate to reach out. Your feedback and stories inspire us to create more delicious content!
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;