import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Home, Book, ShoppingBag, Heart, Coffee, ChevronLeft, ChevronRight, Mail } from 'lucide-react';
import { logEvent } from '../utils/analytics'; 

const Sidebar = ({ isOpen, onToggle, onClose }) => {
  return (
    <>
      {/* Mobile sidebar */}
      <motion.div 
        className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
        style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
      />
      <motion.div 
        className="fixed top-0 left-0 h-screen bg-component-bg border-r border-border overflow-hidden flex z-50 md:hidden"
        initial={{ x: "-100%" }}
        animate={{ x: isOpen ? 0 : "-100%" }}
        transition={{ type: "spring", stiffness: 400, damping: 40 }}
        style={{ width: '256px' }}
      >
        <SidebarContent isOpen={isOpen} onToggle={onToggle} onClose={onClose} />
      </motion.div>

      {/* Desktop sidebar */}
      <div className="hidden md:block fixed top-0 left-0 h-screen bg-component-bg border-r border-border overflow-hidden z-50">
        <motion.div 
          className="h-full"
          initial={false}
          animate={{ width: isOpen ? 256 : 64 }}
          transition={{ type: "spring", stiffness: 400, damping: 40 }}
        >
          <SidebarContent isOpen={isOpen} onToggle={onToggle} onClose={onClose} />
        </motion.div>
      </div>
    </>
  );
};

const SidebarContent = ({ isOpen, onToggle, onClose }) => (
  <div className="flex flex-col h-full">
    <div className="flex items-center justify-end p-4">
      <motion.button
        onClick={() => {
          onToggle();
          logEvent('Sidebar', 'Toggle', isOpen ? 'Close' : 'Open');
        }}
        className="p-1 rounded-full text-text hover:bg-button-hover hover:text-text-light focus:outline-none focus:ring-2 focus:ring-border"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        {isOpen ? <ChevronLeft className="h-5 w-5" /> : <ChevronRight className="h-5 w-5" />}
      </motion.button>
    </div>
    <nav className="flex-1 mt-4">
      <SidebarLink to="/" icon={Home} text="Home" isOpen={isOpen} onClose={onClose} />
      <SidebarLink to="/recipes" icon={Book} text="Recipes" isOpen={isOpen} onClose={onClose} />
      <SidebarLink to="/favorites" icon={Heart} text="Favorites" isOpen={isOpen} onClose={onClose} />
      <SidebarLink to="/coming-soon" icon={Coffee} text="Blog" isOpen={isOpen} onClose={onClose} />
      <SidebarLink to="/contact" icon={Mail} text="Contact" isOpen={isOpen} onClose={onClose} />
    </nav>
  </div>
);

const SidebarLink = ({ to, icon: Icon, text, isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    onClose();
    logEvent('Sidebar Navigation', 'Click', text);  // Log the click event
    navigate(to);
  };

  return (
    <a
      href={to}
      onClick={handleClick}
      className="flex items-center px-4 py-2 text-sm font-light text-text hover:bg-button-hover hover:text-text-light"
    >
      <Icon className="h-5 w-5 flex-shrink-0" />
      <AnimatePresence>
        {isOpen && (
          <motion.span 
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: 'auto' }}
            exit={{ opacity: 0, width: 0 }}
            transition={{ duration: 0.2 }}
            className="ml-3 whitespace-nowrap"
          >
            {text}
          </motion.span>
        )}
      </AnimatePresence>
    </a>
  );
};

export default Sidebar;