import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

// Include the products array directly in this component
const products = [
    {
      link: "https://rstyle.me/+Ojx2wD3w6HPQdmQDlG-7CA",
      title: "Larch Wood Medium Carving Board",
      image: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202423/0018/img35xl.jpg"
    },
    {
      link: "https://rstyle.me/+q5jj7Kcv4V1TZq9wTmz4vQ",
      title: "Porcelain Cup",
      image: "https://lp2.hm.com/hmgoepprod?set=format%5Bwebp%5D%2Cquality%5B79%5D%2Csource%5B%2F25%2Fb8%2F25b8e970868044cdbc25c3492b13fa8da7c2c222.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BDESCRIPTIVESTILLLIFE%5D%2Cres%5Bm%5D%2Chmver%5B2%5D&call=url%5Bfile%3A%2Fproduct%2Fmain%5D"
    },
    {
      link: "https://rstyle.me/+Glg-FXI6WEyFU_A2Ah5W4g",
      title: "Garlic Keeper",
      image: "https://m.media-amazon.com/images/I/51TkpKg3-LL._AC_SL1500_.jpg"
    },
    {
      link: "https://rstyle.me/+S8QIcYyNSrMLKDlxwTprkw",
      title: "Magnolia Table Book",
      image: "https://target.scene7.com/is/image/Target/GUEST_7045a8fd-58fa-4625-9af5-7d8693655de3?wid=1200&hei=1200&qlt=80&fmt=webp"
    },
    {
      link: "https://rstyle.me/+B_IR1N4V__JSZI5cU96zLA",
      title: "Wire Storage Basket",
      image: "https://target.scene7.com/is/image/Target/GUEST_efe86eae-04b3-4a40-b0c6-ffcdb651f04d?wid=1200&hei=1200&qlt=80&fmt=webp"
    },
    {
      link: "https://rstyle.me/+Qw9HdMwjXGgz32hEUwe_vQ",
      title: "Glass Storage Canister",
      image: "https://target.scene7.com/is/image/Target/GUEST_e4480050-f377-4dcd-9740-daa451116bae?wid=1200&hei=1200&qlt=80&fmt=webp"
    },
    {
      link: "https://rstyle.me/+xa92loTccTWtDc_LBCovnA",
      title: "Call it Home Book",
      image: "https://m.media-amazon.com/images/I/813-cazWwUL._SL1500_.jpg"
    },
    {
      link: "https://rstyle.me/+skrtb2pz4628AXJA-z6VGg",
      title: "Charcoal Incense Burner",
      image: "https://m.media-amazon.com/images/I/71obpf-cBZL._AC_SL1500_.jpg"
    },
    {
      link: "https://rstyle.me/+J1cPLfxEHuvHH0s3vXOkEA",
      title: "Moon & Star Platter",
      image: "https://i.etsystatic.com/14640683/r/il/a94472/5691113672/il_1588xN.5691113672_1vq8.jpg"
    }
  ];

const SearchResults = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q');
  const [results, setResults] = useState({ recipes: [], favorites: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      try {
        // Fetch recipes
        const recipesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/`, {
          params: { search: query }
        });
        
        // Filter favorites
        const favoritesResults = products.filter(product =>
          product.title.toLowerCase().includes(query.toLowerCase())
        );

        setResults({
          recipes: recipesResponse.data.results,
          favorites: favoritesResults
        });
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch search results. Please try again later.');
        setLoading(false);
      }
    };

    if (query) {
      fetchResults();
    }
  }, [query]);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <div className="bg-background min-h-screen p-4 sm:p-6 md:p-8">
      <motion.h1 
        className="text-3xl sm:text-4xl font-normal text-center text-text mb-8 sm:mb-12 font-the-seasons"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Search Results for "{query}"
      </motion.h1>

      {loading ? (
        <div className="text-center py-10">Loading...</div>
      ) : error ? (
        <div className="text-center py-10 text-red-500">{error}</div>
      ) : (
        <div>
          <h2 className="text-2xl font-normal text-text mb-4 font-the-seasons">Recipes</h2>
          <AnimatePresence>
            <motion.div 
              className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 mb-8"
              layout
            >
              {results.recipes.map((recipe) => (
                <RecipeCard key={recipe.id} recipe={recipe} />
              ))}
            </motion.div>
          </AnimatePresence>

          <h2 className="text-2xl font-normal text-text mb-4 font-the-seasons">Favorites</h2>
          <AnimatePresence>
            <motion.div 
              className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-3 sm:gap-4"
              layout
            >
              {results.favorites.map((product, index) => (
                <FavoriteCard key={product.title} product={product} index={index} />
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

const RecipeCard = ({ recipe }) => {
  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 }
      }}
    >
      <Link to={`/recipes/${recipe.id}`} className="block">
        <div className="relative pb-[150%] mb-2 overflow-hidden">
          <img 
            src={recipe.images[0]?.image || "/api/placeholder/400/600"} 
            alt={recipe.title} 
            className="absolute top-0 left-0 w-full h-full object-cover object-center"
          />
        </div>
        <h3 className="text-center text-lg font-normal text-text hover:text-text-light transition-colors duration-300 font-the-seasons">{recipe.title}</h3>
      </Link>
    </motion.div>
  );
};

const FavoriteCard = ({ product, index }) => {
  return (
    <motion.div
      className="bg-white rounded-md overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.05 }}
      layout
    >
      <a href={product.link} target="_blank" rel="noopener noreferrer" className="block">
        <div className="relative pb-[100%] overflow-hidden">
          <img 
            src={product.image} 
            alt={product.title} 
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
        </div>
        <div className="p-2">
          <h2 className="text-xs font-normal text-text hover:text-text-light transition-colors duration-300 font-the-seasons line-clamp-2">
            {product.title}
          </h2>
        </div>
      </a>
    </motion.div>
  );
};

export default SearchResults;