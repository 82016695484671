import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Home from './components/Home';
import SingleRecipePage from './components/SingleRecipePage';
import AllRecipes from './components/AllRecipes';
import MyFavorites from './components/Favorites';
import SearchResults from './components/Search';
import './fonts.css';
import SimpleComingSoonPage from './components/ComingSoon';
import AboutPage from './components/About';
import ContactPage from './components/ContactPage';
import { initGA, logPageView } from './utils/analytics';

const AppContent = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div 
      className="flex flex-col bg-background"
      style={{ minHeight: `${viewportHeight}px` }}
    >
      <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <div className="flex flex-1 pt-16">
        <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} onClose={closeSidebar} />
        <main className={`flex-1 overflow-x-hidden overflow-y-auto bg-background transition-all duration-300 ${isSidebarOpen ? 'md:ml-64' : 'md:ml-16'}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/recipes/:id" element={<SingleRecipePage />} />
            <Route path="/recipes" element={<AllRecipes />} />
            <Route path="/favorites" element={<MyFavorites />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/coming-soon" element={<SimpleComingSoonPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>
      </div>
      <Footer />
    </div>
  );
};

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;