import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Search, ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';

const AllRecipesPage = () => {
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchRecipes();
  }, [searchTerm, sortBy, sortOrder, currentPage]);

  const fetchRecipes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/`, {
        params: {
          search: searchTerm,
          ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`,
          page: currentPage,
        },
      });
      setRecipes(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch recipes. Please try again later.');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchRecipes();
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
    setCurrentPage(1);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setCurrentPage(1);
  };

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
    >
      <h1 className="text-4xl font-normal text-text mb-8 font-the-seasons">All Recipes</h1>

      <div className="flex flex-col md:flex-row justify-between items-center mb-8">
        <form onSubmit={handleSearch} className="w-full md:w-auto mb-4 md:mb-0">
          <div className="relative">
            <input
              type="text"
              placeholder="Search recipes..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full md:w-80 bg-white border border-border rounded-full py-2 pl-4 pr-10 text-sm font-light focus:outline-none focus:ring-2 focus:ring-border focus:border-border transition duration-300 ease-in-out"
            />
            <button type="submit" className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <Search className="h-4 w-4 text-text" />
            </button>
          </div>
        </form>

        <div className="flex items-center">
          <select
            value={sortBy}
            onChange={handleSortChange}
            className="bg-white border border-border rounded-full py-2 px-4 text-sm font-light focus:outline-none focus:ring-2 focus:ring-border focus:border-border transition duration-300 ease-in-out mr-2"
          >
            <option value="title">Title</option>
            <option value="prep_time">Prep Time</option>
            <option value="cooking_time">Cooking Time</option>
          </select>
          <button
            onClick={toggleSortOrder}
            className="bg-white border border-border rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-border focus:border-border transition duration-300 ease-in-out"
          >
            <ChevronDown className={`h-4 w-4 text-text transform ${sortOrder === 'desc' ? 'rotate-180' : ''}`} />
          </button>
        </div>
      </div>

      {loading ? (
        <div className="text-center py-10">Loading...</div>
      ) : error ? (
        <div className="text-center py-10 text-red-500">{error}</div>
      ) : (
        <motion.div 
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6"
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
          initial="hidden"
          animate="show"
        >
          {recipes.map((recipe) => (
            <RecipeCard key={recipe.id} recipe={recipe} />
          ))}
        </motion.div>
      )}

      <div className="flex justify-center mt-8">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="mr-2 p-2 rounded-full bg-white border border-border disabled:opacity-50"
        >
          <ChevronLeft className="h-4 w-4 text-text" />
        </button>
        <span className="mx-4 text-text">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="ml-2 p-2 rounded-full bg-white border border-border disabled:opacity-50"
        >
          <ChevronRight className="h-4 w-4 text-text" />
        </button>
      </div>
    </motion.div>
  );
};

const RecipeCard = ({ recipe }) => {
  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 }
      }}
    >
      <Link to={`/recipes/${recipe.id}`} className="block">
        <div className="relative pb-[150%] mb-2 overflow-hidden">
          <img 
            src={recipe.images[0]?.image || "/api/placeholder/400/600"} 
            alt={recipe.title} 
            className="absolute top-0 left-0 w-full h-full object-cover object-center"
          />
        </div>
        <h3 className="text-center text-lg font-normal text-text hover:text-text-light transition-colors duration-300 font-the-seasons">{recipe.title}</h3>
      </Link>
    </motion.div>
  );
};

export default AllRecipesPage;