import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import ImanSaharImage from '../images/imanSahar.JPG';

const Home = () => {
    const [latestRecipes, setLatestRecipes] = useState([]);
    const [currentRecipeIndex, setCurrentRecipeIndex] = useState(0);

    const favorites = [
        {
          link: "https://rstyle.me/+Ojx2wD3w6HPQdmQDlG-7CA",
          title: "Larch Wood Medium Carving Board",
          image: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202423/0018/img35xl.jpg"
        },
        {
          link: "https://rstyle.me/+q5jj7Kcv4V1TZq9wTmz4vQ",
          title: "Porcelain Cup",
          image: "https://lp2.hm.com/hmgoepprod?set=format%5Bwebp%5D%2Cquality%5B79%5D%2Csource%5B%2F25%2Fb8%2F25b8e970868044cdbc25c3492b13fa8da7c2c222.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BDESCRIPTIVESTILLLIFE%5D%2Cres%5Bm%5D%2Chmver%5B2%5D&call=url%5Bfile%3A%2Fproduct%2Fmain%5D"
        },
        {
          link: "https://rstyle.me/+Glg-FXI6WEyFU_A2Ah5W4g",
          title: "Garlic Keeper",
          image: "https://m.media-amazon.com/images/I/51TkpKg3-LL._AC_SL1500_.jpg"
        },
        {
          link: "https://rstyle.me/+S8QIcYyNSrMLKDlxwTprkw",
          title: "Magnolia Table",
          image: "https://target.scene7.com/is/image/Target/GUEST_7045a8fd-58fa-4625-9af5-7d8693655de3?wid=1200&hei=1200&qlt=80&fmt=webp"
        },
        {
          link: "https://rstyle.me/+B_IR1N4V__JSZI5cU96zLA",
          title: "Wire Storage Basket",
          image: "https://target.scene7.com/is/image/Target/GUEST_efe86eae-04b3-4a40-b0c6-ffcdb651f04d?wid=1200&hei=1200&qlt=80&fmt=webp"
        },
        {
          link: "https://rstyle.me/+Qw9HdMwjXGgz32hEUwe_vQ",
          title: "Glass Storage Canister",
          image: "https://target.scene7.com/is/image/Target/GUEST_e4480050-f377-4dcd-9740-daa451116bae?wid=1200&hei=1200&qlt=80&fmt=webp"
        },
    ];

    const tikTokVideos = [
        { id: 1, embedCode: '<blockquote class="tiktok-embed" cite="https://www.tiktok.com/@imannsahar/video/7392222382741671199" data-video-id="7392222382741671199" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@imannsahar" href="https://www.tiktok.com/@imannsahar?refer=embed">@imannsahar</a> the most refreshing mediterranean chickpea salad for the summertime 🍋 video inspired by @Shey Nascimento Hutzler  <a title="fyp" target="_blank" href="https://www.tiktok.com/tag/fyp?refer=embed">#fyp</a> <a title="salad" target="_blank" href="https://www.tiktok.com/tag/salad?refer=embed">#salad</a> <a title="saladrecipes" target="_blank" href="https://www.tiktok.com/tag/saladrecipes?refer=embed">#saladrecipes</a> <a title="mediterraneanfood" target="_blank" href="https://www.tiktok.com/tag/mediterraneanfood?refer=embed">#mediterraneanfood</a> <a target="_blank" title="♬ ده بيعرف إني رومانسية - *mohammed*raafet" href="https://www.tiktok.com/music/ده-بيعرف-إني-رومانسية-7352566349984598789?refer=embed">♬ ده بيعرف إني رومانسية - *mohammed*raafet</a> </section> </blockquote>' },
        { id: 2, embedCode: '<blockquote class="tiktok-embed" cite="https://www.tiktok.com/@imannsahar/video/7381933959854247199" data-video-id="7381933959854247199" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@imannsahar" href="https://www.tiktok.com/@imannsahar?refer=embed">@imannsahar</a> afghan kofta for dinner 🤍 <a title="kofta" target="_blank" href="https://www.tiktok.com/tag/kofta?refer=embed">#kofta</a> <a title="afghan" target="_blank" href="https://www.tiktok.com/tag/afghan?refer=embed">#afghan</a> <a title="afghankofta" target="_blank" href="https://www.tiktok.com/tag/afghankofta?refer=embed">#afghankofta</a> <a title="afghanrecipes" target="_blank" href="https://www.tiktok.com/tag/afghanrecipes?refer=embed">#afghanrecipes</a> <a title="afghanfood" target="_blank" href="https://www.tiktok.com/tag/afghanfood?refer=embed">#afghanfood</a> <a title="afghanistan" target="_blank" href="https://www.tiktok.com/tag/afghanistan?refer=embed">#afghanistan</a> <a target="_blank" title="♬ original sound - masoodahmadi06" href="https://www.tiktok.com/music/original-sound-7218805024296602414?refer=embed">♬ original sound - masoodahmadi06</a> </section> </blockquote>' },
        { id: 3, embedCode: '<blockquote class="tiktok-embed" cite="https://www.tiktok.com/@imannsahar/video/7389757193735818542" data-video-id="7389757193735818542" style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@imannsahar" href="https://www.tiktok.com/@imannsahar?refer=embed">@imannsahar</a> here\'s the afghan pink tea recipe aka qaimaq chai everyone has been asking for 🇦🇫🍵🌷 RECIPE:  - in a pot, boil 2 teaspoons of green tea leaves with 4 cups of water for 10-15 minutes. - add 1&#47;2 teaspoon of baking soda to the boiling tea. as soon as the foam starts to turn pink, add ice cubes to halt the process. - strain and discard the green tea leaves. - pour the tea back and forth between two pots for 5-10 minutes until it becomes a dark red color. - return the tea to the pot and add 2 cups of milk, 1&#47;2 cup sugar, and 1 teaspoon cardamom powder. stir well. - serve hot with a dollop of homemade qaimaq (clotted cream) on top. <a title="qaimaqchai" target="_blank" href="https://www.tiktok.com/tag/qaimaqchai?refer=embed">#qaimaqchai</a> <a title="pinktea" target="_blank" href="https://www.tiktok.com/tag/pinktea?refer=embed">#pinktea</a> <a title="afghanchai" target="_blank" href="https://www.tiktok.com/tag/afghanchai?refer=embed">#afghanchai</a> <a title="afghanpinktea" target="_blank" href="https://www.tiktok.com/tag/afghanpinktea?refer=embed">#afghanpinktea</a> <a title="afghanfood" target="_blank" href="https://www.tiktok.com/tag/afghanfood?refer=embed">#afghanfood</a> <a title="afghanrecipes" target="_blank" href="https://www.tiktok.com/tag/afghanrecipes?refer=embed">#afghanrecipes</a> <a target="_blank" title="♬ Kabutar - Yosamin Davlatova" href="https://www.tiktok.com/music/Kabutar-7135827618414200833?refer=embed">♬ Kabutar - Yosamin Davlatova</a> </section> </blockquote>' }
    ];

    useEffect(() => {
        const fetchLatestRecipes = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/?ordering=-created_at&limit=3`);
                setLatestRecipes(response.data.results.slice(0, 3));
            } catch (error) {
                console.error('Error fetching latest recipes:', error);
            }
        };

        fetchLatestRecipes();

        // Load TikTok embed script
        const script = document.createElement('script');
        script.src = 'https://www.tiktok.com/embed.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleNext = () => {
        setCurrentRecipeIndex((prevIndex) => (prevIndex + 1) % 3);
    };

    const handlePrev = () => {
        setCurrentRecipeIndex((prevIndex) => (prevIndex - 1 + 3) % 3);
    };

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-background"
        >
            {/* Latest Recipes Section */}
            <section className="py-12 md:py-16">
                <motion.h2 
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    className="text-4xl font-normal text-center text-text mb-12 font-the-seasons"
                >
                    THE LATEST RECIPES
                </motion.h2>
                <div className="hidden md:grid md:grid-cols-3 gap-8">
                    {latestRecipes.map((recipe, index) => (
                        <motion.div
                            key={recipe.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.3 + index * 0.1, duration: 0.5 }}
                        >
                            <RecipeCard
                                id={recipe.id}
                                title={recipe.title}
                                image={recipe.images[0]?.image || "/api/placeholder/400/600"}
                            />
                        </motion.div>
                    ))}
                </div>
                <div className="md:hidden">
                    <AnimatePresence mode="wait">
                        {latestRecipes.length > 0 && (
                            <motion.div
                                key={currentRecipeIndex}
                                initial={{ opacity: 0, x: 300 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -300 }}
                                transition={{ duration: 0.3 }}
                            >
                                <RecipeCard
                                    id={latestRecipes[currentRecipeIndex].id}
                                    title={latestRecipes[currentRecipeIndex].title}
                                    image={latestRecipes[currentRecipeIndex].images[0]?.image || "/api/placeholder/400/600"}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <div className="flex justify-between mt-4">
                        <button onClick={handlePrev} className="text-text">Previous</button>
                        <button onClick={handleNext} className="text-text">Next</button>
                    </div>
                </div>
            </section>

            {/* Favorites Section */}
            <section className="py-12 md:py-16">
                <motion.h2 
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    className="text-4xl font-normal text-center text-text mb-12 font-the-seasons"
                >
                    <Link to="/favorites" className="text-text hover:text-text-light transition-colors duration-300">
                        SHOP MY FAVORITES
                    </Link>
                </motion.h2>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 mb-8">
                    {favorites.map((product, index) => (
                        <motion.div
                            key={product.title}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 0.5 + index * 0.1, duration: 0.5 }}
                            className="bg-white rounded-md overflow-hidden shadow-sm hover:shadow-md transition-all duration-300"
                        >
                            <a href={product.link} target="_blank" rel="noopener noreferrer" className="block">
                                <div className="relative pb-[100%] overflow-hidden">
                                    <img 
                                        src={product.image} 
                                        alt={product.title} 
                                        className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                                    />
                                </div>
                                <div className="p-2">
                                    <h3 className="text-xs font-normal text-text hover:text-text-light transition-colors duration-300 font-the-seasons line-clamp-2">
                                        {product.title}
                                    </h3>
                                </div>
                            </a>
                        </motion.div>
                    ))}
                </div>
            </section>

            {/* TikTok Section */}
            <section className="py-12 md:py-16">
                <motion.h2 
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.9, duration: 0.5 }}
                    className="text-4xl font-normal text-center text-text mb-12 font-the-seasons"
                >
                    FOLLOW ME ON TIKTOK:
                </motion.h2>
                <div className="hidden md:grid md:grid-cols-3 gap-8">
                    {tikTokVideos.map((video, index) => (
                        <motion.div
                            key={video.id}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1 + index * 0.1, duration: 0.5 }}
                            className="flex justify-center items-center"
                        >
                            <div 
                                className="tiktok-embed-wrapper max-w-full"
                                dangerouslySetInnerHTML={{ __html: video.embedCode }}
                            />
                        </motion.div>
                    ))}
                </div>
                <div className="md:hidden flex justify-center items-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 1, duration: 0.5 }}
                        className="tiktok-embed-wrapper max-w-full"
                        dangerouslySetInnerHTML={{ __html: tikTokVideos[0].embedCode }}
                    />
                </div>
            </section>

            {/* About Iman Sahar Section */}
            <section className="py-12 md:py-16">
                <motion.h2 
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.1, duration: 0.5 }}
                    className="text-4xl font-normal text-center text-text mb-12 font-the-seasons"
                >
                    ABOUT IMAN SAHAR
                </motion.h2>
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 1.2, duration: 0.5 }}
                    className="flex flex-col md:flex-row items-center bg-white rounded-lg overflow-hidden shadow-sm"
                >
                    <img src={ImanSaharImage} alt="Iman Sahar" className="w-full md:w-1/3 h-64 md:h-auto object-cover" />
                    <div className="p-6 md:p-8">
                        <h3 className="text-2xl font-normal text-text mb-4 font-the-seasons">Iman Sahar</h3>
                        <p className="text-text font-light leading-relaxed mb-4">
                            Iman Sahar, born and raised in America with Afghan roots, is deeply passionate about preserving and sharing the rich culinary traditions of her heritage. Through this blog, she brings you authentic Afghan recipes passed down through generations, each dish narrating a story of culture, family, and love.
                        </p>
                        <p className="text-text font-light leading-relaxed">
                            Iman's journey doesn't stop at Afghan cuisine; she also embraces the culinary arts of various cultures, blending traditional techniques with contemporary twists. Her mission is to make these cherished recipes accessible to everyone, ensuring that the vibrant flavors of Afghanistan and other cultures continue to inspire and delight food enthusiasts worldwide. Join her in exploring a world of flavors, all while staying connected to the roots that ground her.
                        </p>
                    </div>
                </motion.div>
            </section>
        </motion.div>
    );
};

const RecipeCard = ({ id, title, image }) => {
    return (
        <Link to={`/recipes/${id}`} className="block bg-white">
            <div className="relative pb-[150%] mb-4 overflow-hidden">
                <img 
                    src={image} 
                    alt={title} 
                    className="absolute top-0 left-0 w-full h-full object-cover object-center"
                />
            </div>
            <div className="px-4 pb-6">
                <h3 className="text-xl font-normal text-text hover:text-text-light transition-colors duration-300 font-the-seasons">{title}</h3>
            </div>
        </Link>
    );
};

export default Home;