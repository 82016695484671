import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search } from 'lucide-react';

const products = [
    {
      link: "https://rstyle.me/+Ojx2wD3w6HPQdmQDlG-7CA",
      title: "Larch Wood Medium Carving Board",
      image: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202423/0018/img35xl.jpg"
    },
    {
      link: "https://rstyle.me/+q5jj7Kcv4V1TZq9wTmz4vQ",
      title: "Porcelain Cup",
      image: "https://lp2.hm.com/hmgoepprod?set=format%5Bwebp%5D%2Cquality%5B79%5D%2Csource%5B%2F25%2Fb8%2F25b8e970868044cdbc25c3492b13fa8da7c2c222.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BDESCRIPTIVESTILLLIFE%5D%2Cres%5Bm%5D%2Chmver%5B2%5D&call=url%5Bfile%3A%2Fproduct%2Fmain%5D"
    },
    {
      link: "https://rstyle.me/+Glg-FXI6WEyFU_A2Ah5W4g",
      title: "Garlic Keeper",
      image: "https://m.media-amazon.com/images/I/51TkpKg3-LL._AC_SL1500_.jpg"
    },
    {
      link: "https://rstyle.me/+S8QIcYyNSrMLKDlxwTprkw",
      title: "Magnolia Table",
      image: "https://target.scene7.com/is/image/Target/GUEST_7045a8fd-58fa-4625-9af5-7d8693655de3?wid=1200&hei=1200&qlt=80&fmt=webp"
    },
    {
      link: "https://rstyle.me/+B_IR1N4V__JSZI5cU96zLA",
      title: "Wire Storage Basket",
      image: "https://target.scene7.com/is/image/Target/GUEST_efe86eae-04b3-4a40-b0c6-ffcdb651f04d?wid=1200&hei=1200&qlt=80&fmt=webp"
    },
    {
      link: "https://rstyle.me/+Qw9HdMwjXGgz32hEUwe_vQ",
      title: "Glass Storage Canister",
      image: "https://target.scene7.com/is/image/Target/GUEST_e4480050-f377-4dcd-9740-daa451116bae?wid=1200&hei=1200&qlt=80&fmt=webp"
    },
    {
      link: "https://rstyle.me/+xa92loTccTWtDc_LBCovnA",
      title: "Call it Home",
      image: "https://m.media-amazon.com/images/I/813-cazWwUL._SL1500_.jpg"
    },
    {
      link: "https://rstyle.me/+skrtb2pz4628AXJA-z6VGg",
      title: "Charcoal Incense Burner",
      image: "https://m.media-amazon.com/images/I/71obpf-cBZL._AC_SL1500_.jpg"
    },
    {
      link: "https://rstyle.me/+J1cPLfxEHuvHH0s3vXOkEA",
      title: "Moon & Star Platter",
      image: "https://i.etsystatic.com/14640683/r/il/a94472/5691113672/il_1588xN.5691113672_1vq8.jpg"
    }
  ];

  const MyFavorites = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(products);
  
    useEffect(() => {
      const results = products.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(results);
    }, [searchTerm]);
  
    return (
      <div className="bg-background min-h-screen py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <motion.h1 
            className="text-4xl sm:text-5xl font-normal text-center text-text mb-4 font-the-seasons"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            My Favorites
          </motion.h1>
          <motion.p
            className="text-center text-text text-lg mb-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Shop my favorite home decor items, kitchen essentials, pantry staples and more
          </motion.p>
          
          <motion.div 
            className="mb-12 max-w-md mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className="relative">
              <input
                type="text"
                placeholder="Search favorites..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-3 pl-10 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-text focus:border-transparent transition duration-300"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </motion.div>
  
          <AnimatePresence>
            <motion.div 
              className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-4"
              layout
            >
              {filteredProducts.map((product, index) => (
                <motion.div
                  key={product.title}
                  className="bg-white rounded-md overflow-hidden shadow-sm hover:shadow-md transition-all duration-300"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.5, delay: index * 0.05 }}
                  layout
                >
                  <a href={product.link} target="_blank" rel="noopener noreferrer" className="block">
                    <div className="relative pb-[100%] overflow-hidden">
                      <img 
                        src={product.image} 
                        alt={product.title} 
                        className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                      />
                    </div>
                    <div className="p-2">
                      <h2 className="text-xs font-normal text-text hover:text-text-light transition-colors duration-300 font-the-seasons line-clamp-2">
                        {product.title}
                      </h2>
                    </div>
                  </a>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
  
          {filteredProducts.length === 0 && (
            <motion.p
              className="text-center text-text mt-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              No favorites found matching your search.
            </motion.p>
          )}
        </div>
      </div>
    );
  };
  
  export default MyFavorites;