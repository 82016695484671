import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaTiktok, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-component-bg text-text py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center md:flex-row md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link to="/" className="text-2xl font-light hover:text-text-light transition-colors duration-300" style={{ fontFamily: '"The Seasons", serif' }}>
              iman sahar
            </Link>
          </div>
          
          <nav className="mb-6 md:mb-0">
            <ul className="flex flex-wrap justify-center md:justify-end space-x-6">
              <li><Link to="/" className="hover:text-text-light transition-colors duration-300">Home</Link></li>
              <li><Link to="/recipes" className="hover:text-text-light transition-colors duration-300">Recipes</Link></li>
              <li><Link to="/about" className="hover:text-text-light transition-colors duration-300">About</Link></li>
              <li><Link to="/contact" className="hover:text-text-light transition-colors duration-300">Contact</Link></li>
            </ul>
          </nav>

          <div className="flex space-x-4">
            <a href="mailto:contact@imansahar.com" className="hover:text-text-light transition-colors duration-300">
              <FaEnvelope size={24} />
            </a>
            <a href="https://www.instagram.com/imannsahar/" target="_blank" rel="noopener noreferrer" className="hover:text-text-light transition-colors duration-300">
              <FaInstagram size={24} />
            </a>
            <a href="https://www.tiktok.com/@imannsahar" target="_blank" rel="noopener noreferrer" className="hover:text-text-light transition-colors duration-300">
              <FaTiktok size={24} />
            </a>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-border text-center">
          <p className="text-sm">&copy; {new Date().getFullYear()} Iman Sahar. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;